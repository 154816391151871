export default {
  game: {
    duzy: {
      name: 'duzy',
      title: 'Duży lotek',
      liczb: 49,
      losowanych: 6,
      skreslen: {
        od: 6,
        do: 6
      },
      addon: 0
    },
    duzyplus: {
      name: 'duzyplus',
      title: 'Duży lotek plus',
      liczb: 49,
      losowanych: 6,
      skreslen: {
        od: 6,
        do: 6
      },
      addon: 0
    },
    multi: {
      name: 'multi',
      title: 'Multi multi',
      liczb: 80,
      losowanych: 20,
      skreslen: {
        od: 1,
        do: 10
      },
      addon: 0
    },
    mini: {
      name: 'mini',
      title: 'Mini Lotek',
      liczb: 42,
      losowanych: 5,
      skreslen: {
        od: 5,
        do: 5
      },
      addon: 0
    },
    extrapensja: {
      name: 'extrapensja',
      title: 'Extra Pensja',
      liczb: 35,
      losowanych: 5,
      skreslen: {
        od: 5,
        do: 5
      },
      addon: 1,
      addon_cfg: {
        liczb: 4,
        losowanych: 1
      }
    },
    eurojackpot: {
      name: 'eurojackpot',
      title: 'Eurojackpot',
      liczb: 50,
      losowanych: 5,
      skreslen: {
        od: 5,
        do: 5
      },
      addon: 1,
      addon_cfg: {
        liczb: 12,
        losowanych: 2
      }
    }
  },
  system: {
    navigation: {
      aktualizacja: {
        name: '/aktualizacja',
        title: 'Aktualizuj losowania'
      }
    },
    losowania: {
      proste: {
        name: 'losowania',
        type: 'losowanie-proste',
        title: 'Proste'
      },
      montecarlo: {
        name: 'losowania',
        type: 'losowanie-montecarlo',
        title: 'Monte Carlo'
      }
    },
    moje: {
      mojeLosowania: {
        name: 'losowania',
        type: 'moje-losowania',
        title: 'Moje losowania'
      },
      wyloguj: {
        name: 'wyloguj',
        type: 'me',
        title: 'Wyloguj się'
      }
    },
    gameType: {
      losowania: {
        name: 'losowania',
        type: 'losowania',
        title: 'Losowania'
      },
      hash: {
        name: 'losowania',
        type: 'hash',
        title: 'Hash table'
      },
      okresowosc: {
        name: 'losowania',
        type: 'okresowosc',
        title: 'Okresowość'
      },
      pary: {
        name: 'losowania',
        type: 'pary',
        title: 'Pary'
      },
      miesiace: {
        name: 'losowania',
        type: 'miesiace',
        title: 'Miesiące'
      },
      histogram: {
        name: 'losowania',
        type: 'histogram',
        title: 'Histogram'
      }
    }
  }
}
