<template>
<nav class="navbar navbar-expand-md navbar-dark bg-dark" v-if="authenticated">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Lotto</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="graMenu" v-for="(link, id) in navi" v-bind:key="id"><router-link :to="urlclass + link.name + '/' + this.$route.params.type">{{ link.title }}</router-link></li>

        <li class="nav-item dropdown statystyki">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Statystyki
          </a>
          <ul class="dropdown-menu">
            <li v-for="(link, id) in gameType_link" v-bind:key="id">
              <router-link :to="{ name: link.name, params: {slug: (this.$route.params.slug ? this.$route.params.slug : 'duzy'), type: link.type}}">{{ link.title }}</router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown typylosowan">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Losowania
          </a>
          <ul class="dropdown-menu">
            <li v-for="(link, id) in losowania_link" v-bind:key="id"><router-link :to="{ name: link.name, params: {slug: (this.$route.params.slug ? this.$route.params.slug : 'duzy'), type: link.type}}">{{ link.title }}</router-link></li>
          </ul>
        </li>
        <li class="nav-item dropdown mojekonto">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Moje:
          </a>
          <ul class="dropdown-menu">
            <li v-for="(link, id) in moje_link" v-bind:key="id"><router-link :to="{ name: link.name, params: {slug: (this.$route.params.slug ? this.$route.params.slug : 'duzy'), type: link.type}}">{{ link.title }}</router-link></li>
          </ul>
        </li>
        <li class="updateBase" @click="aktualizacja">Aktualizuj</li>
      </ul>
    </div>
  </div>
</nav>
<div class="infomodal" v-if="infoshow">
  <div class="wrapper">
    <h1>Ahtualizacja w toku .... Proszę czekać ....</h1>
  </div>
</div>
</template>

<script>
import store from '@/storage/'
import Losowanie from '@/models/LosowanieModel.js'

export default {
  mounted () {
    this.authenticated = this.$cookies.get('userLogin')
  },
  methods: {
    aktualizacja () {
      this.infoshow = true
      const Loso = new Losowanie('owca')
      Loso.aktualizuj().then(result => {
        this.infoshow = false
      })
    }
  },
  data () {
    return {
      navi: store.game,
      infoshow: false,
      gameType_link: store.system.gameType,
      losowania_link: store.system.losowania,
      moje_link: store.system.moje,
      authenticated: null,
      urlclass: '/game/'
    }
  }
}
</script>
