<template>
  <h1>Wylogowano</h1>
</template>

<script>
export default {
  name: 'WylogujView',
  mounted () {
    this.$cookies.remove('userLogin')
    window.location.replace('/')
  }
}
</script>
