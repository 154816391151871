import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import cookie from 'vue3-cookies'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './assets/scss/app.scss'

const app = createApp(App)
app.use(cookie)
app.use(router)
app.mount('#app')
