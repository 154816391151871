<template>
  <section class="vh-100 logowanie-wrapper">
    <div class="container-fluid h-custom">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-md-9 col-lg-6 col-xl-5">
          <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
            class="img-fluid" alt="Sample image">
        </div>
        <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
          <form id="login" @submit="login">
            <h2>Logowanie</h2>
            <p>Podaj login i hasło.</p>
            <!-- Email input -->
            <div class="form-outline mb-4">
              <input type="text" v-model="input.username" name="username" class="form-control form-control-lg"
                placeholder="Login" @keydown.enter="login()" />
            </div>

            <!-- Password input -->
            <div class="form-outline mb-3">
              <input type="password" v-model="input.password" name="password" class="form-control form-control-lg"
                placeholder="Hasło" @keydown.enter="login()" />
            </div>

            <div class="text-center text-lg-start mt-4 pt-2">
              <button type="button" class="btn btn-primary btn-lg" v-on:click="login()"
                style="padding-left: 2.5rem; padding-right: 2.5rem;">Login</button>
            </div>

          </form>
        </div>
        <div class="col-12">
          <p class="system-version">
            <b>Aktualizacja:</b> 09.05.2023.12.45 <br />
            <b>Wersja:</b> 1.0.5
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomeView',
  mounted () {
    if (this.$cookies.get('userLogin')) {
      this.$router.push('/game/duzy/losowania')
    }
  },
  methods: {
    getUser (user) {
      const dt = this.mockAccount[user]
      return dt
    },
    login () {
      if (this.input.username !== '' && this.input.password !== '') {
        const user = this.getUser(this.input.username)
        if (this.input.username === user.username && this.input.password === user.password) {
          this.$cookies.set('userLogin', user.username)
          window.location.replace('/game/duzy/losowania')
        } else {
          console.log('The username and / or password is incorrect')
        }
      } else {
        console.log('A username and password must be present')
      }
    }
  },
  data () {
    return {
      input: {
        username: '',
        password: ''
      },
      mockAccount: {
        123: {
          username: '123',
          password: '1234'
        },
        lukasz: {
          username: 'lukasz',
          password: '1234'
        }
      }
    }
  }
}
</script>

<style scoped>
.logowanie-wrapper{
  background-image: linear-gradient(to right top, #333b48, #29406d, #30408f, #5336aa, #840db9);
  display: flex;
  justify-content: center;
  align-items: center;

}
.logowanie-wrapper h2, .logowanie-wrapper p {
  color:#fff;
  text-shadow: 4px 4px 6px rgba(66, 68, 90, 1);
}
</style>
